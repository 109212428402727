<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-form-group :label="$t('Dil Seçiniz')" label-for="sDilKodu">
          <v-select
            v-model="selectedLanguage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dilOptions"
            :reduce="(val) => val.value"
            @input="fetchWebsiteGenel"
            input-id="selectedLanguage"
          />
           </b-form-group>
        </b-col>
         
      </b-row>
      <b-row class="mt-2">
       <b-col cols="12" md="12" lg="12">
          <b-form-group :label="$t('Başlık')" label-for="sBaslik">
            <b-form-input v-model="websiteGenel.sBaslik" type="text" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" md="12" lg="12">
          <label for="etiketList" class="mb-1">{{ $t('Etiketler') }}</label>
          <b-form-tags
            v-model="etiketList"
            :placeholder="$t('Etiketleri enter ile giriniz')"
            remove-on-delete
            input-id="etiketList"
            class="mb-2"
          />
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" md="12" lg="12">
        <b-form-group :label="$t('Açıklama')">
          <b-form-textarea
            id="textarea-aciklama"
            :placeholder="$t('Açıklama')"
            v-model="websiteGenel.sAciklama"
            rows="3"
          />
        </b-form-group>
        </b-col>
        <b-col cols="12" md="12" lg="12">
        <b-form-group label="Script">
          <b-form-textarea
            id="textarea-script"
            placeholder="Script"
            v-model="websiteGenel.sScript"
            rows="8"
          />
        </b-form-group>
        </b-col>
        <b-col cols="12" md="12" lg="12">
        <b-form-group label="Style">
          <b-form-textarea
            id="textarea-style"
            placeholder="Style"
            v-model="websiteGenel.sStyle"
            rows="8"
          />
        </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <save-button
            :onClickSave="saveWebsiteGenel"
            :showSaveAndClose="false"
            :showClose="false"
          />
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue'
import ApiService from '@/common/api.service'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

// Options
import StaticOptions from '@/common/options/StaticOptions';

export default {
  components: {
    SaveButton,
  },
  directives: {
    Ripple,
  },
  props: {
    websiteGenelData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      dilOptions: StaticOptions.dilOptions,
      selectedLanguage: 'tr',
      etiketList: [],
      websiteGenel: {
        sAciklama: null,
        sEtiketler: null,
        sBaslik: null,
        sScript: null,
        sStyle: null,
      },
    }
  },
  methods: {
    fetchWebsiteGenel() {
      if (!this.selectedLanguage) {
        return;
      }

      ApiService.get(`manage/websitegenel/get/${this.selectedLanguage}`, {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.websiteGenel = response.result;
            if (this.websiteGenel.sEtiketler) {
              this.etiketList = this.websiteGenel.sEtiketler.split(',')
            } else {
              this.etiketList = []
            }
          } else {
            AlertService.error(this, response.message);
          }
        })
    },

    saveWebsiteGenel() {
      this.websiteGenel.sDilKodu = this.selectedLanguage;
      if (this.etiketList.length) {
        this.websiteGenel.sEtiketler = this.etiketList.join()
      }

      ApiService.post('manage/websitegenel/save', this.websiteGenel, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
  },

  mounted() {
    this.fetchWebsiteGenel();
  },
}
</script>
